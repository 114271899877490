/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Internal dependencies
 */
import Blockquote from '../components/blockquote';
import ClientList from '../components/client-list';
import Form from '../components/form';
import Hero from '../components/hero';
import Layout from '../components/layout';
import Section from '../components/section';
import SEO from '../components/seo';
import ButtonGroup from '../components/button-group';
import Button from '../components/button';


const HeadlessWordPressDeveloperPage = ( props ) => {
    const {
        location,
    } = props;

    return (
        <Layout
            location={ location }
            hideHeader
        >
            <SEO
                title="Freelance Gatsby Developer"
                description="I'm a UK based freelance Gatsby developer working with clients around the world to build blazing fast websites."
            />
            <Hero
                imageName="tom-hirst-headshot.png"
                imageAlt="I'm a freelance Gatsby developer!"
                imageCaption="I'm a freelance Gatsby developer!"
                noHeader
                standardHeight
            >
                <h1>Freelance Gatsby Developer</h1>
                <p className="lead">I&apos;m a UK based freelance Gatsby developer working with clients around the world
                    to build blazing fast websites.</p>
                <p>If you&apos;ve found this page, the chances are that you already know Gatsby is:</p>
                <ul className="check-list">
                    <li>
                        <FontAwesomeIcon icon="check" />
                        A modern JavaScript front end framework
                    </li>
                    <li>
                        <FontAwesomeIcon icon="check" />
                        Based on React
                    </li>
                    <li>
                        <FontAwesomeIcon icon="check" />
                        Super fast
                    </li>
                    <li>
                        <FontAwesomeIcon icon="check" />
                        Usable with any back end
                    </li>
                </ul>
                <p>
                    You were looking for a freelance web developer who knows Gatsby and now you&apos;ve found one!
                </p>
                <ButtonGroup>
                    <Button to="#hire-me" icon="arrow-right" text="Hire me today" color="green" anchor />
                    <Button to="#read-more" icon="arrow-down" text="Read more about me" anchor />
                </ButtonGroup>
            </Hero>
            <Section narrow>
                <h2 id="read-more">What does Tom Hirst know about being a freelance Gatsby developer?</h2>
                <p>I&apos;ve been working as a freelance web developer for over a decade and in the last few years have
                    turned my focus towards Gatsby.</p>
                <p>It is my belief that modern JavaScript is the way forward for website development, which is why I
                    invest my time heavily into building sites for people on Gatsby.</p>
                <p>Clients from across the UK, the USA, Australia and Europe have all hired me on a
                    freelance basis to deliver best in class Gatsby websites.</p>
                <p>Having partnered on web development projects for the likes of the NHS, Facebook, BMW, Umbro and TGI
                    Fridays, I come experienced and trusted.</p>
                <p>I take on all aspects of Gatsby website development on projects of all sizes. Whether your project is
                    small, medium or large, I can be your freelance Gatsby developer.</p>
                <h2>I have a data API - can you develop a front end for this using Gatbsy?</h2>
                <p>Absolutely. Gatsby is perfect for this.</p>
                <p>We can even build a Gatsby website using multiple data APIs or data sources.</p>
                <h2>Can you build a back end to work with Gatsby too?</h2>
                <p>Yes. I have full-stack development experience.</p>
                <p>I can build you a headless content management solution using WordPress, Contentful or similar.</p>
                <h2>Can you develop a finished website using Gatbsy on the front end?</h2>
                <p>I can handle your website requirements from start to finish.</p>
                <p>This would include planning, research, design, back end and front end development using Gatsby.</p>
                <p>Plus rounds of improvement and aftercare after your website has gone live.</p>
                <h2 id="hire-me">Ready to start your Gatsby project?</h2>
                <p>
                    I&apos;d love to work with you on your Gatsby project.
                    Give me the details by filling out the form below:
                </p>
                <Form
                    id="contact-form"
                    contact
                    buttonAsideText="I'll reply as soon as I can!"
                    messagePlaceholder="Hi Tom, I have a Gatsby project needing expert attention."
                    pathname={ location && location.pathname }
                />
                <h2>I&apos;ve worked on websites for</h2>
                <ClientList />
                <h2>I&apos;m experienced, trusted and easy to work with</h2>
                <div className="blockquote-list-with-middle">
                    <Blockquote
                        quote="Tom is structured, accountable, timely, stable and easy to talk to. I would recommend working with him to anyone who is on the lookout for a stellar senior developer."
                        cite="Hillevi Røstad, CAO at Dekode"
                        imageName="hr-headshot.jpg"
                        imageAlt="Hillevi Røstad"
                        boxed
                        mediumQuote
                    />
                </div>
                <h2>Ready to start moving your Gatsby project forward?</h2>
                <a className="button is-green has-icon" href="#hire-me">
                    Yes! Take me back to the form
                    <FontAwesomeIcon icon="arrow-up" />
                </a>
            </Section>
        </Layout>
    );
};

HeadlessWordPressDeveloperPage.propTypes = {
    location: PropTypes.objectOf( PropTypes.any ),
};

HeadlessWordPressDeveloperPage.defaultProps = {
    location: {},
};

export default HeadlessWordPressDeveloperPage;
